@import '../../config/variables.scss';

.privacy {
    text-align: start;
    h3 {
        font-style: italic;
        margin: 5% 0 .5%;
        font-size: 24px;
        @media (min-width: 768px) {
            font-size: 30px;
        }
    }
    p {
        display: inline-block;
        flex-direction: column;
        align-items: flex-start;
        text-align: start;
        margin-bottom: 15px;
        font-size: 20px;
        @media (min-width: 768px) {
            margin-bottom: 20px;
            font-size: 24px;
            line-height: 33px;
        }
    }
}
