@import '../../config/variables.scss';

.contact-methods {
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 5%;
    border-radius: 20px;
    padding: 30px 2%;
    box-shadow: 1px 3px 10px 0px #d9d9d9, 0px 0px 0px #d9d9d9;
    @media (min-width: 1000px) {
        width: 900px;
        margin: 3% auto;
        padding: 50px 2%;
    }
    @media (min-width: 768px) and (max-width: 999px) {
        margin: 3% 7%;
        padding: 50px 2%;
    }
    p {
        color: $grey;
        padding-bottom: .5%;
        &:first-child {
            padding-bottom: 1.1%;
        }
        font-size: 24px;
        @media (min-width: 768px) {
            font-size: 26px;
        }
        font-style: italic;
    }
    .link {
        font-style: normal !important;
    }
}

.contact-form {
    display: flex;
    flex-direction: column;
}

.c-contact {
    &__form {
        display: flex;
        flex-direction: column;
        text-align: center;
        input, textarea {
            border: solid .5px #d1d1d1;
            @media (min-width: 768px) {
                border: solid 1px $grey;
            }
            border-radius: 20px;
            color: $grey;
            font-family: $serif;
            padding: 10px 15px;
            width: 100%;
            margin-top: 5px;
        }
        textarea {
            height: 250px;
        }
    }
    &__label {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: start;
        align-items: flex-start;
        font-size: 20px;
        padding: 1% 0 2%;
        font-style: italic;
    }
    &__inputText {
        font-size: 18px;
        padding: 5px 0;
        border-radius: 20px;
        box-shadow: 1px 2px 2px 0px #e4e4e4, 0px 0px 0px #e4e4e4;
    }
}

.c-contact__submit {
    box-shadow: 1px 2px 2px 0px #e4e4e4, 0px 0px 0px #e4e4e4;
    font-family: $serif;
    font-size: 20px;
    padding: 5px 25px;
    background-color: transparent;
    border: solid .5px #e4e4e4;
    @media (min-width: 768px) {
        border: solid 1px $grey;
    }
    border-radius: 20px;
    transition: $trans;
    color: $grey;
    margin-top: 2%;
    &:hover {
        cursor: pointer;
        background-color: $white;
        color: $black;
    }
}