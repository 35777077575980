@import '../../config/variables.scss';

.hero {
    .social {
        margin: 5px 0;
    }
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    @media (min-width: 768px) {
        display: block;
        flex-direction: row;
    }

    &-left-panel {
        width: 100%;
        min-height: 50vh;
        height: 400px;
        position: static;
        @media (min-width: 768px) {
            width: 38%;
            height: 100vh;
            position: fixed;
            top: 0;
        }
    }
    &-img {
        width: 100%;
        height: 100%;
        background-image: url('/img/scaledme.jpg');
        background-color: $black;
        background-size: cover;
        background-position: 80% 35%;
    }

    &-right-panel {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        float: none;
        @media (min-width: 768px) {
            width: 62%;
            float: right;
            min-height: 100vh;
        }
        footer {
            width: auto;
            margin: 3% 5%;
            @media (min-width: 768px) {
                margin: 0 6% 1%;
            }
        }
    }
    &-content {
        // font-family: $serif;
        color: $black;
        flex: 1;
        height: 100%;
        margin: 3% 5%;
        @media (min-width: 768px) {
            margin: 3% 6%;
        }
        .hero-title, .hero-desc {
            margin: 0 0 25px 0;
            font-weight: 400;
        }
        .hero-title {
            font-size: 4em;
            @media (min-width: 768px) {
                font-size: 70px;
            }

        }
        .hero-desc {
            font-size: 26px;
            line-height: 1.2em;
        }
        p {
            font-weight: 100;
            line-height: 1.4em;
            margin: 0 0 15px 0;
            color: $grey;
            font-size: 19px;
            @media (min-width: 768px) {
                font-size: 20px;
            }
        }
    }
    &-links {
        font-size: 24px;
        margin-bottom: 25px;
        color: $grey;
        width: 100%;
        a {
            border-right: solid .5px $grey;
            margin: 0;
            padding: 0 15px;
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                border-right: 0;
            }
            @media (min-width: 768px) {
                padding: 0 28px;
            }
            // @media (min-width: 768px) {
            //     margin: 0 12px;
            //     &:first-child {
            //         margin: 0 12px 0 0;
            //     }
            //     padding: 0 24px 0 0;
            //     &:last-child {
            //         border-right: 0;
            //         padding-right: 0;
            //     }
            // }
        }
    }
}
