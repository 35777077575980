@import '../../config/variables.scss';

.site-title {
    display: flex;
    justify-content: center;
    color: $black;
    font-size: 3.8em;
    margin-top: 5%;
    @media (min-width: 768px) {
        margin-top: 3%;
    }
}

.nav {
    display: flex;
    justify-content: center;
    ul {
        display: flex;
        list-style-type: none;
    }
    margin: 5%;
    padding-bottom: 5%;
    border-bottom: solid .1px $grey;
    @media (min-width: 768px) {
        margin: 3% 0 2%;
        border-bottom: 0;
        padding-bottom: 0;
    }
    a {
        font-size: 24px;
        margin: 0 auto;
        @media (min-width: 768px) {
            margin: 0 12px;
            &:first-child {
                margin: 0 12px 0 0;
            }
            border-right: solid .5px $grey;
            padding-right: 24px;
            &:last-child {
                border-right: 0;
                padding-right: 0;
            }
        }
    }
}

.selected {
    color: $grey !important;
    text-decoration: none !important;
    font-weight: 600;
}