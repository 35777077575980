$serif: adobe-garamond-pro, serif;


$link-trans: all .15s ease-in-out;
$trans: all .3s ease-in-out;


$black: #2B2D42;
// $grey: #8D99AE;
$grey: #737373;
$white: #EdF2F4;
// $highlight: #DDA455;
$highlight: #d3a360;
// $highlight: #bd8c47;
// $highlight: #D90429;