@import '../../config/variables.scss';

.post {
    &-date {
        color: $grey;
    }
    &-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        img {
            width: 100%;
            margin: 10px 0;
        }
    }
    &-img-desc {
        font-size: 18px;
        @media (min-width: 768px) {
            font-size: 20px;
        }
        font-style: italic;
        color: $grey;
        margin-bottom: 25px;
        text-align: start;
        align-items: flex-start;
    }
    &-para {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: start;
        margin-bottom: 25px;
        font-size: 20px;
        @media (min-width: 768px) {
            margin-bottom: 30px;
            font-size: 24px;
            line-height: 33px;
        }
    }
}

