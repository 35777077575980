@import '../../config/variables.scss';

.card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // position: relative;
}

.card {
    &:nth-child(odd) {
        background-color: $white;
        border-radius: 20px;
        box-shadow: 1px 3px 10px 0px #d9d9d9, 0px 0px 0px #d9d9d9;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 5% 3%;
    margin: 5% 0;
    &-title {
        font-size: 28px;
        margin: 1% 0;
        font-style: italic;
    }
    &-build {
        font-size: 20px;
        line-height: 1.4em;
        margin: 0 0 15px 0;
        color: $grey;
        padding-bottom: 3%;
    }
    img {
        width: 100%;
    }
    &-link {
        color: $highlight;
        text-decoration: dotted;
        transition: $trans;
        img {
            transition: $trans;
            &:nth-child(2) {
                position: absolute;
                width: 20%;
                top: 15%;
                right: 5%;
            }
        }
        &:hover {
            cursor: pointer;
            img {
                &:first-child {
                    @media (min-width: 768px) {
                        transform: scale(1.1);
                    }
                }
                &:nth-child(2) {
                    @media (min-width: 768px) {
                        transform: scale(1.3);
                        top: 25%;
                        right: 2%;
                    }
                    @media (min-width: 1200px) {
                        right: -5%;
                        top: 20%;
                    }
                    @media (min-width: 768px) and (max-width: 1199px) {
                        right: 1%;
                        top: 20%;
                    }
                }
            }
        }
        &-arrow {
            color: $highlight;
            display: block;
            margin: 10px 0 5px;
            font-size: 20px;
            @media (min-width: 768px) {
                display: none;
            }
            svg {
                fill: $highlight;
                width: 10px;
                margin: 0 5px;
            }
        }
    }
}