@import './components/home/home.scss';
@import './components/header/header.scss';
@import './components/footer/footer.scss';
@import './components/contact/contact.scss';
@import './components/portfolio/portfolio.scss';
@import './components/blog/blog.scss';
@import './components/privacy/privacy.scss';
@import './components/project/project.scss';
@import './components/error/error.scss';
@import './components/post/post.scss';

body {
    font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
    font-family: $serif;
    @media (max-width: 767px) {
        min-width: 315px;
    }
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.link {
    font-style: italic;
    text-decoration: underline dotted;
    color: $highlight;
    transition: $link-trans;
    &:hover {
        color: $grey;
        text-decoration: underline;
    }
}

.page {
    &-wrap {
        display: flex;
        flex-direction: column;
        text-align: center;
        color: $black;
        margin: 5%;
        position: relative;
        @media (min-width: 1000px) {
            width: 900px;
            margin: 3% auto;
        }
        @media (min-width: 768px) and (max-width: 999px) {
            margin: 3% 7%;
        }
    }
    &-title {
        display: flex;
        justify-content: center;
        color: $black;
        text-align: center;
        line-height: 1.2em;
        font-weight: 400;
        width: 100%;
        font-size: 24px;
        margin: 2% 0 5% 0;
        @media (min-width: 768px) {
            font-size: 30px;
            margin: 2% 0;
        }
    }
    &-desc {
        font-size: 20px;
        color: $grey;
        font-family: $serif;
        text-align: center;
    }
    &-link {
        display: flex;
        justify-content: center;
        text-align: center;
        margin: 1% 0 5%;
        font-size: 20px;
        svg {
            margin: 0 6px;
            fill: $highlight;
            width: 10px;
            transition: $link-trans;
        }
        .link {
            &:hover {
                svg {
                    fill: $grey;
                }
            }
        }
    }
}

.slide-in {
    transform: translate(0, 50%) scale(1);
    opacity: 0;
    transition: all .85s;
}

.slide-in.active {
    opacity: 1;
    transform: translate(0, 0%) scale(1);
}