@import '../../config/variables.scss';

.decor {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    overflow: hidden;
    height: 60%;
    @media(min-width: 768px) {
        height: auto;
    }
}

.project-title {
    display: flex;
    justify-content: center;
    text-align: center;
    color: $black;
    line-height: 1.2em;
    font-style: italic;
    margin: 3% 0 2%;
    font-size: 24px;
    @media (min-width: 768px) {
        font-size: 30px;
    }
}

.img-container {
    img {
        width: 100%;
        margin: 5% 0 10%;
        padding-bottom: 5%;
    }
}
.img-container-small {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    background-color: $white;
    border-radius: 20px;
    margin: 10vh 0 15vh;
    img {
        height: 100%;
        position: relative;
        top: -60px;
        width: 40%;
        margin: 3% auto;
        &:nth-child(even) {
            position: relative;
            top: 60px;
        }
    }
}