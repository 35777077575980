@import '../../config/variables.scss';

footer {
    @media (min-width: 1000px) {
        width: 900px;
        margin: 3% auto;
    }
}

.social {
    display: flex;
    flex-direction: column;
    border-top: solid 2px $highlight;
    @media (min-width: 768px) {
        border-top: solid 3px $highlight;
    }
    padding-top: 12px;
    margin: 3% 5%;
    @media (min-width: 1000px) {
        margin: 0;
    }
}

.social-container {
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
    }
    .copyright {
        font-size: 15px;
        color: $grey;
        letter-spacing: 1.1px;
        margin-bottom: 15px;
        @media (min-width: 768px) {
            margin: 0;
        }
    }
    a {
        padding: 0 5px;
        svg {
            fill: $grey;
            transition: $trans;
        }
        &:hover {
            svg {
                fill: $highlight;
            }
        }
    }
}