@import '../../config/variables.scss';

.page-wrap-blog {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-family: $serif;
    color: $black;
    margin: 5%;
    position: relative;
    @media (min-width: 1000px) {
        width: 900px;
        margin: 3% auto;
    }
    @media (min-width: 768px) and (max-width: 999px) {
        margin: 3% 7%;
    }
}

.post {
    box-shadow: 1px 3px 10px 0px #d9d9d9, 0px 0px 0px #d9d9d9;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    font-size: 24px;
    img {
        width: 100%;
    }
    width: 100%;
    margin: 5% 0 10%;
    @media (min-width: 768px) {
        width: 47.5%;
        margin: 5% 0 .5% 0;
        &:nth-child(even) {
            margin-left: 5%;
            transition-delay: 250ms;
        }
    }
    &-content {
        flex: 1;
        h3 {
            font-style: italic;
            margin: 2% 0;
            font-size: 26px;
        }
        margin: 0 2%;
        @media (min-width: 768px) {
            margin: 0 4%;
        }
    }
    &-build {
        font-size: 20px;
        margin-bottom: 5%;
        color: $grey;
    }
    &-link {
        color: $highlight;
        text-decoration: none;
        font-style: italic;
        font-size: 22px;
        img {
            transition: $link-trans;
            overflow: hidden;
        }
        &:hover {
            text-decoration: underline;
        }
        &-arrow {
            // margin: 0 5px 0 5px;
            color: $highlight;
            display: block;
            svg {
                fill: $highlight;
                width: 12px;
                margin: 0 5px 0 5px;
                transition: $link-trans;
            }
        }
    }
}

.share {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 0 0 5px;
    height: 35px;
    a {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding: 1px 2px;
        margin: auto 5px;
    }
    svg {
        margin: 0 auto;
        fill: #999999;
        transition: $link-trans;
        &:hover {
            fill: $highlight;
        }
    }
}

.box {
    // border: solid 2px $highlight;
    box-shadow: 0px 2px 3px 2px #d9d9d9, 0px 0px 0px #d9d9d9;
    border-radius: 5px;
    position: relative;
    top: 21px;
    background-color: #fff;


}